import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TimeInputCustomControlDirective } from './time-input-custom.directive';
import { TimeInputComponent } from './time-input.component';

@NgModule({
  declarations: [TimeInputComponent, TimeInputCustomControlDirective],
  imports: [CommonModule, FormsModule, TranslateModule, ReactiveFormsModule],
  exports: [TimeInputComponent, TimeInputCustomControlDirective],
})
export class TimeInputModule {}
